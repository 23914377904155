import { MenuItem, Stack, TextField } from '@mui/material'
import { VendorSearchState } from '../../states/vendor-list'
import { DEFAULT_OPTION_VALUE, options } from './constants'

type VendorFilterTypeProps = {
  filters: VendorSearchState['filters']
  search: VendorSearchState['search']
}

export function VendorFilterType(props: VendorFilterTypeProps) {
  const { filters, search } = props

  function setSupplierType(value: string) {
    void search({ supplierType: value })
  }

  const selectedOption = options.find((opt) => {
    return opt.value === filters.supplierType
  })

  const value = selectedOption?.value ?? DEFAULT_OPTION_VALUE

  return (
    <TextField
      size="medium"
      select={true}
      sx={{ backgroundColor: 'background.paper' }}
      fullWidth={true}
      onChange={(e) => {
        const option = options.find((opt) => opt.value === e.target.value)

        if (!option) {
          return
        }

        setSupplierType(option.value)
      }}
      value={value}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Stack flexDirection="row" justifyItems="center" gap={1.5}>
            <option.icon /> {option.label}
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  )
}
