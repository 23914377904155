import styled from 'styled-components'
import { mobileMediaQuery } from '@teamfeedr/ui--theme'
import { Text } from '@teamfeedr/ui--typography'

export const ModalWrapper = styled.div`
  overflow: visible;
  & div {
    overflow: visible;
    & > div:first-child > button {
      display: none;
    }
  }
  & > div > div {
    min-height: 305px;
    height: auto;
    max-height: 60%;
    width: 95%;
    max-width: 580px;
    h2 {
      padding-right: 0;
      font-size: ${(p) => p.theme.fontSizes.xl};
      & > span {
        margin-bottom: 16px;
        justify-content: center;
      }
    }
  }
  @media ${mobileMediaQuery} {
    & > div > div {
      height: 100%;
      max-height: none;
      width: 100%;
      max-width: none;
    }
    input {
      width: 100%;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  margin: 0;
  padding: 10px 0;
  border: 1px solid ${(p) => p.theme.colors.background_dark};
  border-width: 1px 0;

  @media ${mobileMediaQuery} {
    display: block;
    padding: 0;
    border: 0;
  }
`

export const HideErrors = styled.div`
  position: relative;
  & > div > span:last-child,
  & > div > div > span:last-child {
    display: none;
  }
`

export const LeftSide = styled(Text)`
  align-self: center;
  width: 100%;
`

export const RightSide = styled(Text)`
  align-self: center;
  & > div {
    grid-template-columns: 1fr;
  }

  @media ${mobileMediaQuery} {
    border-top: 1px solid ${(p) => p.theme.colors.background_dark};
    padding: 8px 0;
    margin-top: 8px;
    background-color: ${(p) => p.theme.colors.background_lightest};
    & > div {
      grid-template-columns: 7fr 1fr;
    }
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
