import { useApolloClient } from '@apollo/client'
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { z } from 'zod'
import { basketLocationToAddress } from '@teamfeedr/utils--gm-validation'
import {
  useVendorsGmLazyQuery,
  VendorsGmQuery,
  VendorsGmQueryVariables,
  UserBasketSettingsLocationFragment,
  Address,
  VendorMarketplaceOrderByInput,
  ServiceType,
} from '@/generated/graphql'
import { useAppError } from '../../utils/errors'
import { lookupAddressDetails } from '@/components/page-specific/gm/helpers/addresses'
import { useAppState } from './app'
import {
  userStateDateTimeToUTCString,
  userStateTimeToHoursAndMins,
} from '@/components/page-specific/gm/helpers/date'
import useUrlSearchFilters from '@/components/page-specific/gm/helpers/useUrlSearchFilters'
import { ParsedUrlQueryInput } from 'querystring'
import { useRouter } from '@/components/Link'
import useTenant from '@/hooks/useTenant'
import moment from 'moment'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

export type VendorSearchState = {
  pageInfo?: VendorsGmQuery['vendors']['pageInfo']
  vendors: VendorsGmQuery['vendors']['rows']
  stats: VendorsGmQuery['vendors']['stats']
  searching: boolean
  called: boolean
  search: (query: Partial<VendorsGmQueryVariables>) => void
  toggleFilterOpen: () => void
  filterOpen: boolean
  filters: Partial<VendorsGmQueryVariables>
  filterCount: number
  defaultSearch: () => void
}

export type TagGroupNames = {
  id: string
  key: 'cuisineTags' | 'dietaryTags' | 'keyDatesTags' | 'occasionTags' | 'diversityOfOwnershipTags'
  label: string
}
export const tagGroupNames: Record<string, TagGroupNames> = {
  Cuisine: {
    id: '4',
    key: 'cuisineTags',
    label: 'Cuisine',
  },
  Dietary: {
    id: '1',
    key: 'dietaryTags',
    label: 'Dietary',
  },
  KeyDates: {
    id: '16',
    key: 'keyDatesTags',
    label: 'Key dates',
  },
  Occasion: {
    id: '3',
    key: 'occasionTags',
    label: 'Occasion',
  },
  DiversityOfOwnership: {
    id: '21',
    key: 'diversityOfOwnershipTags',
    label: 'Diversity of ownership',
  },
}

const defaultVariables: VendorsGmQueryVariables = {
  dateTime: null,
  queryVersion: 2,
  offset: 0,
  search: '',
  location: null,
  headCount: null,
  keyDatesTags: null,
  diversityOfOwnershipTags: null,
  cuisineTags: null,
  occasionTags: null,
  dietaryTags: null,
  minDateTime: null,
  highlightsTags: null,
  corporateTags: null,
  tenantId: null,
  maxRating: 5,
  serviceType: ServiceType.Gm,
  orderBy: {
    direction: 'asc',
    property: 'distance',
  } as VendorMarketplaceOrderByInput,
  deliveryRegionFilter: { serviceType: ServiceType.Gm },
  supplierType: null,
}

const routerQuerySchema = z.object({
  search: z.string().default(''),
  mealTypes: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  headCountMin: z.string().optional(),
  headCountMax: z.string().optional(),
  headCount: z.preprocess((val) => {
    if (!val) return 0
    if (typeof val === 'string') {
      return Number(val)
    }
  }, z.number().optional()),
  priceLevelRange: z
    .preprocess((val) => {
      if (!val) return []
      if (typeof val === 'string') {
        const parsedNum = Number(val)
        if (parsedNum > 3 || parsedNum < 1) return []
        return [parsedNum]
      }
      return (val as string[]).reduce((acc: number[], v: string) => {
        const parsedNum = Number(v)
        if (parsedNum > 3 || parsedNum < 1) return acc
        return [...acc, parsedNum]
      }, [])
    }, z.number().min(1).max(3).array())
    .default([]),
  keyDatesTags: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  cuisineTags: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  diversityOfOwnershipTags: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  occasionTags: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  highlightsTags: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  dietaryTags: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  corporateTags: z
    .preprocess((val) => {
      return typeof val === 'string' ? [val] : val || []
    }, z.string().array())
    .default([]),
  lat: z.preprocess((val) => Number(val), z.number()).optional(),
  lng: z.preprocess((val) => Number(val), z.number()).optional(),
  postcode: z.string().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  minDateTime: z.string().optional(),
  date: z.string().optional(),
  time: z.string().optional(),
  orderByDirection: z.string().optional(),
  orderByProperty: z.string().optional(),
  queryVersion: z.preprocess((val) => Number(val), z.number()).default(2),
  supplierType: z.enum(['pantry', 'catering', 'all']).default('all').nullable().optional(),
})
let timeout: ReturnType<typeof setTimeout> | null = null

export const useVendorSearchState = (): VendorSearchState => {
  const { throwAppError } = useAppError()
  const apolloClient = useApolloClient()
  const router = useRouter()
  const { userState, updateUserState } = useAppState()
  const [previousVendors, setPreviousVendors] = useState<VendorsGmQuery['vendors']['rows']>([])
  const [filterOpen, toggleFilterOpen] = useState(false)
  const [urlSearchFilters, setUrlSearchFilters] = useUrlSearchFilters()
  const tenant = useTenant()
  const getFiveStarRating = useFeatureFlag(SPLITS.FIVE_STAR_RATINGS)

  const parsedUrlFilters = routerQuerySchema.parse(urlSearchFilters)

  const filtersMappedFromUrl = {
    ...parsedUrlFilters,
    ...(parsedUrlFilters.mealTypes && { mealType: parsedUrlFilters.mealTypes }),
    ...(parsedUrlFilters.orderByProperty && {
      orderBy: {
        property: parsedUrlFilters.orderByProperty,
        direction: parsedUrlFilters.orderByDirection || 'asc',
      } as VendorMarketplaceOrderByInput,
    }),
    ...(parsedUrlFilters.lat &&
      parsedUrlFilters.lng && {
        location: { latitude: parsedUrlFilters.lat, longitude: parsedUrlFilters.lng },
      }),
  }

  const filtersMappedFromUrlRef = useRef(filtersMappedFromUrl)

  const getAddress = React.useCallback((): Pick<
    Address,
    'city' | 'street' | 'postcode' | 'latitude' | 'longitude'
  > | null => {
    if (userState.location) {
      return basketLocationToAddress(userState.location)
    }
    if (filtersMappedFromUrlRef.current.postcode && filtersMappedFromUrlRef.current.street) {
      return {
        postcode: filtersMappedFromUrlRef.current.postcode,
        street: filtersMappedFromUrlRef.current.street,
        city: filtersMappedFromUrlRef.current.city || null,
        latitude: filtersMappedFromUrlRef.current.lat || null,
        longitude: filtersMappedFromUrlRef.current.lng || null,
      }
    }
    return null
  }, [userState.location])

  const address = getAddress()

  const location =
    address?.latitude && address?.longitude
      ? {
          latitude: address?.latitude,
          longitude: address?.longitude,
        }
      : null
  if (address && (!address?.latitude || !address?.longitude)) {
    void lookupAddressDetails(address, apolloClient, true).then((addressFound) => {
      if (addressFound) {
        address.latitude = addressFound.latitude
        address.longitude = addressFound.longitude
        updateUserState({ location: address as UserBasketSettingsLocationFragment })
      }
    })
  }
  const [findVendors, vendorData] = useVendorsGmLazyQuery({
    notifyOnNetworkStatusChange: true,
  })

  const dateTime = userState.date
    ? {
        date: moment(userState.date).date(),
        month: moment(userState.date).month(),
        year: moment(userState.date).year(),
        hour: userState.time ? Number(userStateTimeToHoursAndMins(userState.time).hours) : 0,
        minute: userState.time ? Number(userStateTimeToHoursAndMins(userState.time).minutes) : 0,
        timezone: 'UTC',
      }
    : null

  const refetchVendors = ({
    offset,
    search,
    headCount,
    keyDatesTags,
    cuisineTags,
    occasionTags,
    dietaryTags,
    highlightsTags,
    diversityOfOwnershipTags,
    minDateTime,
    orderBy,
    supplierType,
  }: Partial<VendorsGmQueryVariables>) => {
    const setValue = {
      ...(location && { lat: location.latitude, lng: location.longitude }),
      ...(typeof search !== 'undefined' && { search }),
      ...(typeof minDateTime !== 'undefined' && { minDateTime }),
      ...(keyDatesTags && { keyDatesTags }),
      ...(diversityOfOwnershipTags && { diversityOfOwnershipTags }),
      ...(cuisineTags && { cuisineTags }),
      ...(highlightsTags && { highlightsTags }),
      ...(occasionTags && { occasionTags }),
      ...(dietaryTags && { dietaryTags }),
      ...(typeof headCount !== 'undefined' && { headCount }),
      ...(orderBy && {
        ...(orderBy.property && { orderByDirection: orderBy.direction || 'asc' }),
        ...(orderBy.property && { orderByProperty: orderBy.property }),
      }),
      ...(supplierType && { supplierType }),
    }
    void setUrlSearchFilters(setValue as ParsedUrlQueryInput)
    setPreviousVendors(
      offset
        ? Array.from(new Set([...previousVendors, ...(vendorData.data?.vendors.rows || [])]))
        : [],
    )
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(
      () =>
        findVendors({
          variables: {
            serviceType: ServiceType.Gm,
            offset: offset || 0,
            ...filtersMappedFromUrl,
            ...(minDateTime && { minDateTime }),
            ...(typeof search !== 'undefined' && { search }),
            ...(keyDatesTags && { keyDatesTags }),
            ...(cuisineTags && { cuisineTags }),
            ...(diversityOfOwnershipTags && { diversityOfOwnershipTags }),
            ...(highlightsTags && { highlightsTags }),
            ...(occasionTags && { occasionTags }),
            ...(dietaryTags && { dietaryTags }),
            ...(headCount && { headCount }),
            ...(tenant && { tenantId: tenant.id }),
            ...(orderBy && { orderBy }),
            ...(dateTime && { dateTime }),
            ...(supplierType && { supplierType }),
            maxRating: getFiveStarRating ? 5 : 4,
            queryVersion: filtersMappedFromUrl.queryVersion,
          },
        }),
      500,
    )
  }

  const defaultSearch = () => {
    if (vendorData.called) {
      return vendorData.refetch()
    }
    void findVendors({
      variables: {
        ...defaultVariables,
        ...filtersMappedFromUrl,
        queryVersion: filtersMappedFromUrl.queryVersion,
        ...(userState.date && {
          minDateTime: userStateDateTimeToUTCString(userState.date, userState.time).replace(
            'Z',
            'UZ',
          ),
          dateTime,
        }),
        location,
        deliveryRegionFilter: {
          serviceType: ServiceType.Gm,
          ...(!!address?.latitude &&
            !!address?.longitude && {
              location: { lat: address.latitude, lng: address.longitude },
            }),
        },
        ...(tenant && { tenantId: tenant.id }),
      },
    })
  }
  useEffect(() => {
    if (!router.isReady) return
    const address = userState.location ? basketLocationToAddress(userState.location) : null
    const set = {
      ...(userState.date && {
        minDateTime: userStateDateTimeToUTCString(userState.date, userState.time).replace(
          'Z',
          'UZ',
        ),
      }),
      ...(address && { lat: address.latitude, lng: address.longitude }),
    }
    void setUrlSearchFilters(set)
  }, [userState.date, userState.time, userState.location, router.isReady])

  useEffect(() => {
    if (JSON.stringify(filtersMappedFromUrl) === JSON.stringify(filtersMappedFromUrlRef.current))
      return
    filtersMappedFromUrlRef.current = filtersMappedFromUrl
    void refetchVendors({ ...filtersMappedFromUrl })
  }, [filtersMappedFromUrl])

  const filterCount = useMemo(() => {
    const counter = Object.values({ ...filtersMappedFromUrl }).reduce(
      (sum: number, value: unknown | unknown[]) => {
        if (Array.isArray(value)) {
          return sum + value.length
        }
        return sum
      },
      0,
    )
    return counter
  }, [filtersMappedFromUrl])

  if (vendorData.error)
    return throwAppError({ type: 'COULD_NOT_FETCH_VENDORS', cause: vendorData.error })

  const vendors = Array.from(
    new Set([...previousVendors, ...(vendorData.data?.vendors.rows || [])]),
  )

  const state: VendorSearchState = {
    pageInfo: vendorData.data?.vendors.pageInfo,
    vendors,
    stats: vendorData.data?.vendors.stats || vendorData.previousData?.vendors.stats || null,
    called: vendorData.called,
    searching: vendorData.loading,
    search: refetchVendors,
    toggleFilterOpen: () => toggleFilterOpen(!filterOpen),
    filterOpen,
    filters: {
      ...filtersMappedFromUrl,
    },
    filterCount,
    defaultSearch,
  }

  return state
}
