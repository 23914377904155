import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useFeatureFlag, SPLITS } from '@/helpers/useFeatureFlag'
import { VendorSearchState, tagGroupNames } from '../states/vendor-list'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useState } from 'react'
import TagFiltersMui from './tag-filters-mui'
import VendorFilterSort from './sort-mui'
import { VendorFilterType } from './type/component'

type Props = {
  filterOpen: boolean
  toggleFilterOpen: () => void
  filters: VendorSearchState['filters']
  search: VendorSearchState['search']
  stats: VendorSearchState['stats']
  searching: boolean
}

type TagTypeKey =
  | 'cuisineTags'
  | 'dietaryTags'
  | 'keyDatesTags'
  | 'occasionTags'
  | 'diversityOfOwnershipTags'

type FiltersSelected = {
  [key in TagTypeKey]?: boolean
}

const VendorFiltersMui: React.FC<Props> = ({
  filterOpen,
  toggleFilterOpen,
  search,
  filters,
  searching,
  stats,
}) => {
  const countrySpecificFiltersFlagOn = useFeatureFlag(SPLITS.COUNTRY_SPECIFIC_FILTERS_GM)
  const showDiversityTags = useFeatureFlag(SPLITS.SHOW_DIVERSITY_TAGS_GM)
  const showPantryVendorTypeDropdown = useFeatureFlag(SPLITS.USE_PANTRY_VENDOR_TYPE_DROPDOWN)

  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [filterSelected, setFilterSelected] = useState<FiltersSelected>({})

  type OrderBy = 'position' | 'label'

  const getOrderBy = (condition: boolean, defaultValue: OrderBy): OrderBy => {
    return condition ? 'position' : defaultValue
  }

  const tagFiltersConfig = [
    {
      tagType: tagGroupNames.Occasion,
      specificToCountry: countrySpecificFiltersFlagOn,
      orderBy: getOrderBy(countrySpecificFiltersFlagOn, 'label'),
    },
    {
      tagType: tagGroupNames.Cuisine,
      specificToCountry: countrySpecificFiltersFlagOn,
      orderBy: 'position' as OrderBy,
    },
    {
      tagType: tagGroupNames.Dietary,
      orderBy: 'label' as OrderBy,
      useName: true,
    },
    {
      tagType: tagGroupNames.KeyDates,
      specificToCountry: countrySpecificFiltersFlagOn,
      orderBy: getOrderBy(countrySpecificFiltersFlagOn, 'label'),
    },
  ]

  if (showDiversityTags) {
    tagFiltersConfig.push({
      tagType: tagGroupNames.DiversityOfOwnership,
      specificToCountry: countrySpecificFiltersFlagOn,
      orderBy: getOrderBy(countrySpecificFiltersFlagOn, 'label'),
    })
  }

  const updateFilterSelected = (tagTypeKey: TagTypeKey, selected: any) => {
    setFilterSelected((prev) => ({
      ...prev,
      [tagTypeKey]: selected,
    }))
  }

  const clearAll = () => {
    const findSelectedFilters: FiltersSelected = Object.fromEntries(
      Object.keys(filterSelected).map((key) => [key as TagTypeKey, false]),
    ) as FiltersSelected

    setFilterSelected(findSelectedFilters)

    void search({
      [tagGroupNames.Occasion.key]: [],
      [tagGroupNames.Cuisine.key]: [],
      [tagGroupNames.Dietary.key]: [],
      [tagGroupNames.KeyDates.key]: [],
      [tagGroupNames.DiversityOfOwnership.key]: [],
      highlightsTags: [],
    })
  }

  if (isMobileScreen && !filterOpen) return null

  const TagSections = (
    <Box sx={{ width: '100%' }}>
      <Grid
        sx={{ width: '100%' }}
        container
        flexDirection={{ xs: 'column', md: 'row' }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, md: 3 }}
      >
        {showPantryVendorTypeDropdown ? (
          <Grid item xs={2} md={3} xl={2}>
            {!isMobileScreen && <Typography color="text.secondary">Vendor Type</Typography>}
          </Grid>
        ) : null}

        <Grid item xs={2} md={3} xl={2}>
          {!isMobileScreen && <Typography color="text.secondary">Sort by</Typography>}
        </Grid>

        <Grid
          item
          xs={showPantryVendorTypeDropdown ? 8 : 10}
          md={showPantryVendorTypeDropdown ? 4 : 7}
          xl={showPantryVendorTypeDropdown ? 6 : 8}
        >
          {!isMobileScreen && <Typography color="text.secondary">Filters</Typography>}
        </Grid>

        <Grid alignSelf="center" item xs={2} textAlign="end">
          <Button size="small" onClick={clearAll}>
            Clear all
          </Button>
        </Grid>
      </Grid>

      <Grid
        sx={{ width: '100%' }}
        container
        flexDirection={{ xs: 'column', md: 'row' }}
        alignContent={{ xs: 'center' }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, md: 3 }}
      >
        {showPantryVendorTypeDropdown ? (
          <Grid item xs={12} md={3} xl={2}>
            <VendorFilterType filters={filters} search={search} />
          </Grid>
        ) : null}

        <Grid item xs={12} md={3} xl={2}>
          <VendorFilterSort filters={filters} search={search} />
        </Grid>

        <Grid
          item
          xs={10}
          md={showPantryVendorTypeDropdown ? 6 : 9}
          xl={showPantryVendorTypeDropdown ? 8 : 10}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          flexWrap="wrap"
          gap={1}
        >
          {tagFiltersConfig.map((filter) => (
            <TagFiltersMui
              key={`${filter.tagType.key}-${filter.tagType.id}`}
              tagType={filter.tagType}
              stats={stats}
              search={search}
              filters={filters}
              searching={searching}
              filterSelected={filterSelected[filter.tagType.key] || false}
              setFilterSelected={(selected) => updateFilterSelected(filter.tagType.key, selected)}
              defaultOpen={false}
              orderBy={filter.orderBy}
              specificToCountry={filter.specificToCountry}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  )

  return isMobileScreen && filterOpen ? (
    <Dialog fullScreen open={filterOpen}>
      <Toolbar sx={{ marginBottom: '1rem' }}>
        <IconButton edge="start" color="primary" onClick={toggleFilterOpen}>
          <ClearIcon />
        </IconButton>

        <Typography sx={{ ml: 2, flex: 1 }} variant="h4">
          Filter & Sort by
        </Typography>
      </Toolbar>

      {TagSections}
    </Dialog>
  ) : (
    <>{TagSections}</>
  )
}

export default VendorFiltersMui
