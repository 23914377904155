import { amountToPriceString } from '@teamfeedr/utils--money'
import { Divider, Stack, Typography } from '@mui/material'
import { VendorsGmQuery } from '@/generated/graphql'
import React from 'react'

export type Props = {
  vendor: NonNullable<VendorsGmQuery['vendors']['rows']>[number]
}

const LocationDetails: React.FC<Props> = ({ vendor }) => {
  const getVendorLocationDetails = (vendor: Props['vendor']) => {
    const defaultLocation = vendor?.locations?.find(
      (location: { isDefault: boolean }) => location.isDefault,
    )
    if (!defaultLocation) return null
    const priorityLocation = defaultLocation.deliveryRegions.reduce(
      (accumulator, currentValue) =>
        accumulator.orderPosition < currentValue.orderPosition ? accumulator : currentValue,
      defaultLocation.deliveryRegions[0],
    )
    return {
      deliveryFee: priorityLocation?.deliveryFee?.totalExTax || null,
      minOrderFee: priorityLocation?.minOrderValueExTax || null,
    }
  }

  const vendorLocationDetails = getVendorLocationDetails(vendor)
  return (
    <>
      {(vendorLocationDetails?.deliveryFee || vendorLocationDetails?.minOrderFee) && (
        <>
          <Stack flexDirection="row" justifyContent="space-evenly">
            {vendorLocationDetails?.deliveryFee && (
              <Typography variant="body2">
                {amountToPriceString(
                  vendor.currency || 'GBP',
                  vendorLocationDetails?.deliveryFee || 0,
                  true,
                )}{' '}
                <Typography variant="caption" color="text.secondary">
                  Delivery Fee
                </Typography>
              </Typography>
            )}
            {vendorLocationDetails?.minOrderFee && (
              <Typography variant="body2">
                {amountToPriceString(
                  vendor.currency || 'GBP',
                  vendorLocationDetails?.minOrderFee || 0,
                  true,
                )}{' '}
                <Typography variant="caption" color="text.secondary">
                  Minimum Order
                </Typography>
              </Typography>
            )}
          </Stack>
          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />{' '}
        </>
      )}
    </>
  )
}
export default LocationDetails
