import { useRouter } from '@/components/Link'
import { useEffect, useState } from 'react'
import qs from 'query-string'
import { ParsedUrlQueryInput } from 'querystring'

const parseQueryString = (queryObj: ParsedUrlQueryInput) => qs.parse(qs.stringify(queryObj))

const useUrlSearchFilters = (setQueryParams = true) => {
  const router = useRouter()

  const [filters, setFilters] = useState(parseQueryString(router.legacyQuery))

  useEffect(() => {
    const newFilters = parseQueryString(router.legacyQuery)
    if (filters === newFilters) return
    setFilters(parseQueryString(router.legacyQuery))
  }, [router.legacyQuery])

  const handleSetFilters = async (param: ParsedUrlQueryInput) => {
    if (!router.isReady) return
    if (router.pathname !== '/[domain]/[lang]/office-catering/vendors') return
    // @ts-expect-error unknown
    setFilters((prev) => parseQueryString({ ...prev, ...param }))

    if (setQueryParams) {
      const newQuery = parseQueryString({
        ...router.legacyQuery,
        ...param,
      })

      delete newQuery.domain

      await router.replace(
        {
          pathname: router.pathname.replace('[domain]/[lang]/', ''),
          query: qs.stringify(newQuery),
        },
        undefined,
        { scroll: false },
      )
    }
  }

  return [filters, handleSetFilters] as const
}

export default useUrlSearchFilters
