import styled from 'styled-components'

export const TagWrapper = styled.div`
  display: flex !important;
  align-items: center;
  &&& .kitchenTag {
    background: ${(p) => p.theme.colors.background_main};
    svg {
      fill: ${(p) => p.theme.colors.text};
    }
  }
`
