import React from 'react'
import { MenuItem, Paper, TextField } from '@mui/material'
import { VendorSearchState } from '../../states/vendor-list'
import { VendorMarketplaceOrderByProperties, VendorOrderDirection } from '@/generated/graphql'

const options = [
  { value: 'distance-asc', label: 'Distance', data: { property: 'distance', direction: 'asc' } },
  {
    value: 'alphabet-asc',
    label: 'Alphabetical (a-z)',
    data: { property: 'alphabetical', direction: 'asc' },
  },
  {
    value: 'alphabet-desc',
    label: 'Alphabetical (z-a)',
    data: { property: 'alphabetical', direction: 'desc' },
  },
  { value: 'price-asc', label: 'Price (low-high)', data: { property: 'price', direction: 'asc' } },
  {
    value: 'price-desc',
    label: 'Price (high-low)',
    data: { property: 'price', direction: 'desc' },
  },
]

type Props = {
  filters: VendorSearchState['filters']
  search: VendorSearchState['search']
}

const VendorFilterSort: React.FC<Props> = ({ search, filters }) => {
  const setSort = (property: string, direction = 'asc') => {
    void search({
      orderBy: {
        property: property as VendorMarketplaceOrderByProperties,
        direction: direction as VendorOrderDirection,
      },
    })
  }

  const value =
    options.find(
      (option) =>
        option.data.property === filters.orderBy?.property &&
        option.data.direction === filters.orderBy?.direction,
    )?.value || options[0].value

  return (
    <Paper elevation={0}>
      <TextField
        fullWidth
        select
        size="small"
        onChange={(e) => {
          const option = options.find((option) => option.value === e.target.value)
          if (!option) return
          setSort(option.data.property, option.data.direction)
        }}
        value={value}
      >
        {options.map((option) => (
          <MenuItem key={JSON.stringify(option.value)} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Paper>
  )
}

export default VendorFilterSort
