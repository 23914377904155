import React, { useEffect, useState } from 'react'
import { HeaderWrapper } from './index.styles'
import { Fade, Stack, Typography } from '@mui/material'
import Image from 'next/image'
import image1 from '@public/images/gm/catering-banner-1.jpg'
import image2 from '@public/images/gm/catering-banner-2.jpg'
import image3 from '@public/images/gm/catering-banner-3.jpg'

type Props = {
  isPantryOnly: boolean
}

const VendorOverviewHeader: React.FC<Props> = (props) => {
  const { isPantryOnly } = props

  const images = [image1, image2, image3]
  const [visibleImage, setVisibleImage] = useState(0)

  useEffect(() => {
    const showNextImage = () => {
      setVisibleImage((prev) => (prev === images.length - 1 ? 0 : prev + 1))
    }

    const interval = setInterval(() => {
      showNextImage()
    }, 10000)

    return () => clearInterval(interval)
  }, [setVisibleImage, images.length])

  return (
    <HeaderWrapper>
      {images.map((image, index) => (
        <Fade appear={false} timeout={1000} key={image.src} in={index === visibleImage}>
          <Image objectFit="cover" alt="image" src={image.src} layout="fill" />
        </Fade>
      ))}

      <Stack zIndex={1} position="relative" padding={5} direction="column">
        <Typography color="inverted.main" variant="h2">
          {isPantryOnly ? (
            <span>
              Corporate pantry to
              <br />
              make your office magnetic.
            </span>
          ) : (
            <span>
              Corporate catering that
              <br />
              delivers on every occasion.
            </span>
          )}
        </Typography>

        <Typography color="inverted.main" variant="h5">
          Search our hand picked, taste-tested vendors to find the food you&apos;ve been looking
          for.
        </Typography>
      </Stack>
    </HeaderWrapper>
  )
}

export default VendorOverviewHeader
