import styled from 'styled-components'
import { mobileMediaQuery } from '@teamfeedr/ui--theme'

export const CollapseWrapper = styled.div<{ noMargin?: boolean }>`
  margin-top: ${(p) => (p.noMargin ? '0' : p.theme.spacings[4])}px;

  @media ${mobileMediaQuery} {
    margin-top: ${(p) => (p.noMargin ? '0' : p.theme.spacings[3])}px;
    border-top: ${(p) => (p.noMargin ? '0' : 1)}px solid ${(p) => p.theme.colors.background_dark};
    padding-top: ${(p) => (p.noMargin ? '0' : p.theme.spacings[3])}px;
  }
`

export const BoxWrapper = styled.div`
  position: relative;
  margin-top: 8px;
`

export const CheckboxWrapper = styled.div`
  .MuiFormControlLabel-label {
    font-size: ${(p) => p.theme.fontSizes.s};
    font-weight: ${(p) => p.theme.fontWeights.medium};
    color: ${(p) => p.theme.colors.text};
    line-height: 2;
  }
`

export const ShowMoreButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
`

export const ShowMoreText = styled.span`
  color: ${(p) => p.theme.colors.primary};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.s};
  margin-left: 8px;
`

export const FilterWrapper = styled.div`
  display: flex;
`

export const ModalWrapper = styled.div`
  // So annoying that we have to do this to get to the header
  & > div > div > div > div {
    background: ${(p) => p.theme.colors.background_light};
    & > div:first-child {
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      background-color: ${(p) => p.theme.colors.canvas};
      margin-bottom: 32px;
      h2 {
        font-size: ${(p) => p.theme.fontSizes.m};
      }
    }
  }
`
