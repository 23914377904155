import { GridViewRounded, LocalDining, ShoppingBasket } from '@mui/icons-material'

export const options = [
  {
    value: 'pantry',
    label: 'Pantry',
    icon: ShoppingBasket,
  },
  {
    value: 'catering',
    label: 'Catering',
    icon: LocalDining,
  },
  {
    value: 'all',
    label: 'All',
    icon: GridViewRounded,
  },
] as const

type OptionValue = (typeof options)[number]['value']

export const DEFAULT_OPTION_VALUE: OptionValue = 'catering'
