import Link from '@/components/Link'
import { Button, ButtonBase, Card, Skeleton, Stack, Typography } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { useInView } from 'react-intersection-observer'
import Image from 'next/image'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import VendorRating from '../vendor-rating'
import { FragmentType, graphql, useFragment } from '@/generated/gql'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import { useLazyQuery } from '@apollo/client'
import { Coordinates } from '@/generated/graphql'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

const highlightListHighlightCategoryFragment = graphql(`
  fragment HighlightList_HighlightCategoryFragment on VendorHighlightCategory {
    id
    label
    name
  }
`)

const highlightListVendorDataFragment = graphql(`
  fragment HighlightList_VendorDataFragment on VendorHighlightCategoryVendorsPaged {
    pageInfo {
      offset
      limit
      hasMore
      count
    }
    vendors {
      permalink
      companyName
      logo
      images {
        alt
        id
        secureUrl
      }
      ratingOutOfFive: rating(input: { maxRating: 5 })
      ratingOutOfFour: rating(input: { maxRating: 4 })
      totalRatings
    }
  }
`)

const highlightListMoreVendorDataQuery = graphql(`
  query HighlightList_MoreVendorDataQuery(
    $location: Coordinates!
    $highlightCategoryId: String!
    $limit: Int
    $offset: Int
  ) {
    vendorsForHighlightCategoryForLocation(
      location: $location
      highlightCategoryId: $highlightCategoryId
      limit: $limit
      offset: $offset
    ) {
      ...HighlightList_VendorDataFragment
    }
  }
`)

type Props = {
  highlightCategoryData: FragmentType<typeof highlightListHighlightCategoryFragment>
  onViewMoreClick: (tag: string) => void
  vendorData: FragmentType<typeof highlightListVendorDataFragment>
  location: Coordinates
}

const HighlightList: React.FC<Props> = ({
  onViewMoreClick,
  vendorData,
  highlightCategoryData,
  location,
}) => {
  const { ref: listEndRef, inView: listEndInView } = useInView()
  const { ref: listStartRef, inView: listStartInView } = useInView()
  const scrollRef = useRef<HTMLDivElement>(null)
  const showFiveStarRating = useFeatureFlag(SPLITS.FIVE_STAR_RATINGS)
  const { vendors, pageInfo } = useFragment(highlightListVendorDataFragment, vendorData)
  const {
    id: highlightCategoryId,
    label,
    name: highlightName,
  } = useFragment(highlightListHighlightCategoryFragment, highlightCategoryData)

  const [gettingMore, setGettingMore] = useState(false)
  const [getMoreVendors] = useLazyQuery(highlightListMoreVendorDataQuery)
  const [offset, setOffset] = useState<number>(vendors.length)
  const [hasMore, setHasMore] = useState(pageInfo.hasMore)
  const [moreVendors, setMoreVendors] = useState<typeof vendors>([])
  const getMoreLimit = 5
  const vendorsToShow = [...vendors, ...moreVendors]

  const handleShowMore = useCallback(async () => {
    const moreVendorData = await getMoreVendors({
      variables: {
        highlightCategoryId,
        location,
        limit: getMoreLimit,
        offset,
      },
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const vendorData = useFragment(
      highlightListVendorDataFragment,
      moreVendorData.data?.vendorsForHighlightCategoryForLocation,
    )
    if (!vendorData) {
      setHasMore(false)
    } else {
      const { vendors: moreVendors, pageInfo: moreVendorsPageInfo } = vendorData
      setHasMore(moreVendorsPageInfo.hasMore)
      setOffset((prev) => prev + Number(moreVendors.length))
      setMoreVendors((prev) => [...prev, ...moreVendors])
      setGettingMore(false)
    }
  }, [getMoreVendors, highlightCategoryId, location, offset])

  useEffect(() => {
    if (!listEndInView || gettingMore || !hasMore) return
    setGettingMore(true)
    handleShowMore()
  }, [listEndInView, gettingMore, handleShowMore, hasMore])
  const theme = useTheme()

  if (!vendorsToShow.length) return null

  return (
    <Stack spacing={1} direction="column" width="100%">
      <Stack paddingX={1} alignItems="center" direction="row" spacing={1}>
        <Typography variant="subtitle3">{label}</Typography>
        <Typography color="primary.main" variant="body2">
          <Button size="small" variant="text" onClick={() => onViewMoreClick(highlightName)}>
            View all
          </Button>
        </Typography>
      </Stack>
      <Stack position="relative">
        {!listStartInView ? (
          <ButtonBase
            sx={{
              borderBottomRightRadius: '500px',
              borderTopRightRadius: '500px',
              backgroundColor: alpha(theme.palette.secondary.light, 0.2),
              height: '100%',
              position: 'absolute',
              zIndex: 2,
            }}
            onClick={() => {
              scrollRef.current?.scrollBy({
                left: 0 - scrollRef.current.clientWidth * 0.75,
                behavior: 'smooth',
              })
            }}
          >
            <Stack justifyContent="center" height="100%">
              <ArrowBack color="primary" fontSize="large" />
            </Stack>
          </ButtonBase>
        ) : null}
        {!listEndInView ? (
          <ButtonBase
            sx={{
              borderBottomLeftRadius: '500px',
              borderTopLeftRadius: '500px',
              backgroundColor: alpha(theme.palette.secondary.light, 0.2),
              height: '100%',
              position: 'absolute',
              right: 0,
              zIndex: 2,
            }}
            onClick={() => {
              scrollRef.current?.scrollBy({
                left: scrollRef.current.clientWidth * 0.75,
                behavior: 'smooth',
              })
            }}
          >
            <Stack justifyContent="center" height="100%">
              <ArrowForward color="primary" fontSize="large" />
            </Stack>
          </ButtonBase>
        ) : null}
        <Stack
          ref={scrollRef}
          direction="row"
          spacing={1}
          width="100%"
          overflow="scroll"
          paddingRight="1px"
          paddingBottom="1px"
        >
          <div ref={listStartRef} />
          {vendorsToShow.map((vendor) => {
            const vendorImage = vendor.images?.[0]
            const vendorRating = showFiveStarRating
              ? vendor.ratingOutOfFive
              : vendor.ratingOutOfFour
            return (
              <Link key={vendor.permalink} href={`/office-catering/vendors/${vendor.permalink}/`}>
                <Card sx={{ width: '200px', height: '100%' }}>
                  <Stack
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                    height="100px"
                    width="100%"
                  >
                    <Image
                      objectFit="cover"
                      alt={vendor.companyName}
                      src={vendorImage?.secureUrl || ''}
                      fill
                    />
                    {vendor.logo && (
                      <Image
                        src={vendor.logo}
                        width={77}
                        height={77}
                        alt=""
                        style={{
                          zIndex: 1,
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                  </Stack>
                  <Stack padding={2} spacing={1} direction="column">
                    <Typography
                      sx={{
                        minHeight: '3em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                      }}
                      variant="subtitle2"
                    >
                      {vendor.companyName}
                    </Typography>
                    <Typography variant="body2" color="primary.main">
                      {highlightName}
                    </Typography>
                    {(vendorRating || 0) >= 2 && (
                      <VendorRating
                        rating={vendorRating || 0}
                        totalRatings={vendor.totalRatings || 0}
                      />
                    )}
                  </Stack>
                </Card>
              </Link>
            )
          })}
          {gettingMore
            ? Array.from({ length: getMoreLimit }, (_, i) => i).map((num) => (
                <div key={num}>
                  <Skeleton variant="rectangular" height="100%" width={200} />
                </div>
              ))
            : null}
          <div ref={listEndRef} />
          {hasMore && !gettingMore ? <Button onClick={() => handleShowMore()}>More</Button> : null}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default HighlightList
