import { graphql } from '@/generated/gql'
import { useLazyQuery } from '@apollo/client'
import useTenant from '@/hooks/useTenant'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import { useRouter } from 'next/router'
import { z } from 'zod'
import { useEffect, useRef } from 'react'

const vendorHighlightsCategoriesHighlightsQuery = graphql(`
  query VendorHighlightsCategories_HighlightsQuery(
    $location: Coordinates!
    $filters: VendorHighlightCategoriesFilterInput!
    $limit: Int
    $offset: Int
  ) {
    vendorHighlightsForLocation(
      location: $location
      filters: $filters
      limit: $limit
      offset: $offset
    ) {
      pageInfo {
        hasMore
        count
      }
      highlightCategories {
        ...VendorListHighlights_HighlightCategoryFragment
      }
    }
  }
`)

const routerQuerySchema = z.object({
  lat: z.preprocess((val) => Number(val), z.number()).optional(),
  lng: z.preprocess((val) => Number(val), z.number()).optional(),
})

export const useVendorHighlightCategories = () => {
  const router = useRouter()
  const parsedUrlFilters = routerQuerySchema.parse(router.query)
  const getFiveStarRating = useFeatureFlag(SPLITS.FIVE_STAR_RATINGS)
  const showHighlights = useFeatureFlag(SPLITS.SHOW_HIGHLIGHTS_GM)
  const tenant = useTenant()

  const [getHighlightData, highlightsData] = useLazyQuery(vendorHighlightsCategoriesHighlightsQuery)

  const latitude = useRef<number>()
  const longitude = useRef<number>()

  useEffect(() => {
    if (!showHighlights) return
    const { lat, lng } = parsedUrlFilters
    if (typeof lat !== 'number' || typeof lng !== 'number') return
    if (latitude.current === lat && longitude.current === lng) return
    latitude.current = lat
    longitude.current = lng
    getHighlightData({
      variables: {
        location: { latitude: lat, longitude: lng },
        filters: {
          tenantId: tenant.id,
        },
        limit: 10,
        offset: 0,
      },
    })
  }, [parsedUrlFilters, getFiveStarRating, tenant.id, getHighlightData, showHighlights])

  return highlightsData
}
