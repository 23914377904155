import React from 'react'
import { TagGroupNames, VendorSearchState } from '../../states/vendor-list'
import { useTagsQuery } from '@/generated/graphql'
import { FormGroup, Menu, MenuItem, Stack, ToggleButton, Typography } from '@mui/material'
import { getCountryFromLocale } from '@/helpers/multiRegion'
import { useLocale } from '@/components/Link'
import useTenant from '@/hooks/useTenant'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

type Props = {
  tagType: TagGroupNames
  defaultOpen: boolean
  useName?: boolean
  orderBy?: 'position' | 'label'
  specificToCountry?: boolean
  stats: VendorSearchState['stats']
  search: VendorSearchState['search']
  filters: VendorSearchState['filters']
  searching: boolean
  filterSelected: boolean
  setFilterSelected: React.Dispatch<React.SetStateAction<boolean>>
}

const TagFiltersMui: React.FC<Props> = ({
  tagType,
  useName = false,
  orderBy,
  specificToCountry,
  stats,
  search,
  filters,
  searching,
  filterSelected,
  setFilterSelected,
}) => {
  const tenant = useTenant()
  const locale = useLocale()

  const countryCode = getCountryFromLocale(locale || '')
  const defaultValue = filters[tagType.key] as string[]
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const { data, fetchMore } = useTagsQuery({
    variables: {
      filters: {
        groupId: tagType.id,
        tenantId: tenant.id,
        ...(specificToCountry && {
          countryCode,
          isPublicFilterGm: true,
        }),
      },
      limit: 5,
      offset: 0,
      orderBy: orderBy || null,
    },
  })
  const tagFilter = new Set(defaultValue || [])
  const setNewTagFilter = (newTagFilter: Set<string>) => {
    void search({ [tagType.key]: Array.from(newTagFilter) })
  }

  const tags = data?.tags.rows || []

  const showMore = () => {
    void fetchMore({
      variables: {
        filters: {
          groupId: tagType.id,
          tenantId: tenant.id,
          ...(specificToCountry && {
            countryCode,
            isPublicFilterGm: true,
          }),
        },
        limit: 200,
        offset: tags?.length,
        orderBy: orderBy || null,
      },
    })
  }

  let filterLabel = tagType.label
  const filtersTotal = Array.from(tagFilter).length
  if (filtersTotal > 0) filterLabel += ` (${filtersTotal})`
  const filterStats = stats ? stats[tagType.key] : null

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <Stack flexDirection={{ xs: 'column' }} alignItems={{ xs: 'center' }}>
      <ToggleButton
        color="primary"
        value={tagType.id}
        size="large"
        selected={filterSelected}
        onClick={handleClick}
        fullWidth
        sx={{
          minWidth: '250px',
          justifyContent: 'space-between',
          backgroundColor: 'background.paper',
        }}
      >
        {filterLabel}
        {!anchorEl ? <AddIcon /> : <RemoveIcon />}
      </ToggleButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <FormGroup>
          {tags &&
            tags.map((tag) => {
              const keyType = useName && tag.name ? 'name' : 'label'
              const title = tag[keyType]
              if (!title || !tag.groupId) return null
              const filterStatsCount = filterStats?.find((tagId) => tagId[keyType] === title)?.count
              return filterStatsCount ? (
                <MenuItem key={`${tag.id}-${tag.label}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tagFilter.has(title)}
                        onChange={(_, checked) => {
                          const newSet = new Set(tagFilter)
                          setFilterSelected(!filterSelected)
                          if (checked) {
                            newSet.add(title || '')
                          } else {
                            newSet.delete(title || '')
                            setFilterSelected(!filterSelected)
                          }
                          setNewTagFilter(newSet)
                        }}
                        name={tag.groupId}
                      />
                    }
                    label={
                      <Stack alignItems="center" direction="row">
                        {tag.name}&nbsp;
                        <Typography variant="caption" color="grey.500">
                          ({filterStatsCount})
                        </Typography>
                      </Stack>
                    }
                  />
                </MenuItem>
              ) : null
            })}
          {data?.tags.pageInfo.hasMore && !searching && (
            <MenuItem onClick={() => showMore()}>Show more...</MenuItem>
          )}
        </FormGroup>
      </Menu>
    </Stack>
  )
}

export default TagFiltersMui
